
html {
  min-height: 100%;
}

body {
  font-size: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Asap', sans-serif;
}

root {
  min-height: 100%;
}

input, textarea, select, a {
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
}
