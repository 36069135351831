
.HomePage {

  width: 100vw;
  .logout-box {

    position: absolute;
    top: 0;
    right: 0;
    color: white;
    font-weight: bold;
    font-size: 15px;
    background-color: rgba(0, 0, 0, 0.79);
    padding: 10px 16px;
    border-radius: 0 0 0 5px;
    cursor: pointer;
  }

  .top-box {
    background: url(8.jpg) no-repeat center center;
    //background: url(/umami1b.jpg) no-repeat center center;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Oswald', sans-serif;
    font-size: 20px;
    .title {
      padding-top: 20vh;
    }
    .lines {
      position: relative;
      display: inline-block;
      border-top: medium double #fff;
      border-bottom: medium double #fff;
      margin-top: 30px;
      margin-bottom: 30px;
      color: #FFF;
      padding: 2px 0;
      max-width: 90vw;
      width: 470px;
      text-align: center;
    }

    .lines:before {
      display: block;
      font-family: "fontello";
      position: absolute;
      font-size: 22px;
      color: #FFF;
      content: "\e801";
      top: -13px;
      left: 0;
      right: 0;
      margin-left: 19px;

    }

    .lines:after {
      display: block;
      font-family: "fontello";
      position: absolute;
      font-size: 22px;
      color: #f0394d;
      content: "\e801";
      top: -13px;
      left: 0;
      right: 0;
      margin-right: 19px;
    }

    h1 {
      font-size: 40px;
      margin-top: 20px;
      margin-bottom: 20px;
      font-weight: 500;
      line-height: 1.1;
    }

    h4 {
      margin: 0 0 20px;
      text-align: center;
      color: white;
      letter-spacing: 3px;
      font-size: 20px;
    }
  }


  .menu-box-space {
    background-color: white;
    width: 100vw;
    height: 25px;
  }


  .menu-box {
    background-color: white;
    padding: 0 17px;

    text-align: center;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    z-index: 100;

    ul {
      list-style-type: none;
      margin: 0;
      font-family: 'Oswald', sans-serif;
      font-size: 15px;
      display: block;
      //padding: 4px 16px 7px 0;
      padding: 4px 0px 7px 25px;
    }

    li {
      position: relative;
      display: inline;
      color: #6a6a6a;
    }

    li.s:before {
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      content: '\e801';
      color: #f0394d;
      text-align: center;
      position: absolute;
      bottom: 4px;
      left: -5px;

    }

    span {
      display: inline-block;
      padding: 18px;
      color: #666;
      text-decoration: none;
      cursor: pointer;
    }

    .r span {
      padding: 0;
    }


    .rounded {
      border-radius: 50%;
      width: 50px;
      height: 50px;
      background-color: #f0394d;
      color: #FFF;
      text-align: center;
      font-family: 'Roboto Slab', serif;
    }

    .css-logo-text {
      padding-top: 14px;
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 9px;
        baseline-shift: 2px;
      }
    }
  }
  .mobile-menu {
    display: none;
  }

  @media (max-width: 740px) {
    .menu-box {
      display: none;
    }
    .menu-box-space {
      display: none;
    }
    .icons {
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: row;
      background-color: #f0394d;
      div {
        //color: #666;
        color: #fff;
        padding: 7px 0;
        font-size: 24px;
      }
    }
    .mobile-menu {
      position: sticky;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      z-index:999;
      left:0;
      right:0;
      bottom:0;
      overflow: hidden;
    }
    .confirm-box {
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: row;
      background-color: #fff;
      .option {
        width: 50vw;
        text-align: center;
        padding: 11px 0 12px;
      }
      .out {
        color: #f0394d;
      }
    }


  }


  .about-us-box {
    background-color: #f6f6f6;
    padding: 30px 0 100px;




    .container {
      padding-right: 15px;
      padding-left: 15px;
      margin-top: 40px;
      margin-right: auto;
      margin-left: auto;
    }

    @media (min-width: 768px) {
      .container {
        width: 750px;
      }
    }

    @media (min-width: 992px) {
      .container {
        width: 970px;
      }
    }

    img {
      max-width: 100%;
      display: block;
    }

    .white {
      float: left;
      width: 43%;
      background-color: white;
      padding: 8px;
      margin: 0 4% 10px 3%;
    }

    .text {
      //width: 42%;
      margin: 0 3%;
      font-family: 'Roboto Slab', serif;
      font-size: 14px;
      line-height: 21px;
      text-align: justify;
    }

    @media (max-width: 700px) {
      .white {
        width: calc(94% - 16px);
        margin: 0 3% 10px 3%;
        float: none;
      }
      .container {
        display: flex;
        flex-direction: column;
      }
      //.cell {
      //  width: 90%;
      //  margin: 3%;
      //}
    }

    h2 {
      font-size: 40px;
      margin-top: 20px;
      margin-bottom: 20px;
      color: #333333;
      font-family: 'Oswald', sans-serif;
      font-weight: 500;
      line-height: 1.1;
      text-align: center;
    }

    .heart-divider {
      height: 100%;
      width: 100%;
      margin: 40px auto 20px;
      text-align: center;
    }

    .grey-line {
      width: 50px;
      height: 8px;
      display: inline-block;
      border-top: solid 2px #e1dddd;
    }

    .pink-heart {
      color: #f0394d;
      font-size: 19px;
      position: relative;
      z-index: 3;
    }

    .grey-heart {
      color: #e1dddd;
      font-size: 19px;
      margin-left: -15px;
      position: relative;
      z-index: 2;
    }

  }


  .clock-box {
    background: url(3.jpg) no-repeat center center;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Oswald', sans-serif;
    font-size: 20px;
    overflow: hidden;

    .wrap {
      //padding-top: 50vh;
      width: 95vw;
    }

    h2 {
      text-align: center;
      color: #FFF;
      font-family: 'Pacifico', serif;
      font-size: 55px;;
      font-weight: normal;
      transform: rotate(-10deg);
      margin-top: 0;
      margin-bottom: 25px
    }

    .heart-divider {
      height: 100%;
      width: 100%;
      margin: 20px auto;
      text-align: center;
    }

    .white-line {
      width: 50px;
      height: 8px;
      display: inline-block;
      border-top: solid 1px #FFF;
    }

    .pink-heart {
      color: #f0394d;
      font-size: 19px;
      position: relative;
      z-index: 3;
    }

    .white-heart {
      color: #FFF;
      font-size: 18px;
      margin-left: -15px;
      position: relative;
      z-index: 2;
    }

    .Counter {
      //width: 250px;
      margin: 25px auto;
      border: none;
      background-color: transparent;
      color: #FFF;
      font-family: 'Oswald', sans-serif;
      font-size: 30px;
      text-align: center;

      span {
        width: 43px;
        display: inline-block;
        text-align: right;
        margin: 0 10px;
      }
    }
  }


  .info-box {
    background-color: #f6f6f6;
    padding: 60px 0;


    .container {
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
      display: flex;
    }


    @media (min-width: 768px) {
      .container {
        width: 750px;
      }
    }
    @media (min-width: 992px) {
      .container {
        width: 970px;
      }
    }

    img {
      max-width: 100%;
      display: block;
    }

    .cell {
      background-color: white;
      text-align: justify;
      width: 44%;
      margin: 3%;
      font-family: 'Roboto Slab', serif;
      font-size: 14px;
      line-height: 21px;
    }

    @media (max-width: 767px) {
      .container {
        flex-direction: column;
      }
      .cell {
        width: 94%;
        margin: 3%;
      }
    }

    h3 {
      margin: 25px 25px 20px;
      color: #333333;
      font-family: 'Oswald', sans-serif;
      font-weight: 500;
      line-height: 1.1;
      font-size: 20px;
    }
    p {
      margin: 20px 25px 35px;
    }


  }

  .join-us-box {
    background-color: white;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .inner-box {
      margin: 40px 3vw 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    h2 {
      font-size: 40px;
      margin-top: 20px;
      margin-bottom: 30px;
      color: #333333;
      font-family: 'Oswald', sans-serif;
      font-weight: 500;
      line-height: 1.1;
      text-align: center;
    }
    .text {
      font-family: 'Roboto Slab', serif;
      line-height: 1.1;
      font-size: 0.95rem;
      margin: 10px 0 ;
      text-align: center;
    }

    .button {
      font-family: 'Oswald', sans-serif;
      border: 1px solid #6a6a6a;
      margin: 38px 0 0;
      padding: 15px;
      cursor: pointer;
      text-align: center;
      font-weight: bold;
      color: #6a6a6a;
      width: 200px;
      text-decoration: none;
    }
    .button:hover {
      border: 1px solid #f0394d;
      background-color: #f0394d;
      color: white;
    }

    a {

      text-decoration: none;
    }

    .heart-divider {
      height: 100%;
      width: 100%;
      margin: 40px auto 20px;
      text-align: center;
    }

    .grey-line {
      width: 50px;
      height: 8px;
      display: inline-block;
      border-top: solid 2px #e1dddd;
    }

    .pink-heart {
      color: #f0394d;
      font-size: 19px;
      position: relative;
      z-index: 3;
    }

    .grey-heart {
      color: #e1dddd;
      font-size: 19px;
      margin-left: -15px;
      position: relative;
      z-index: 2;
    }
  }

  .play-box {
    background: url(6.jpg) no-repeat center center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    .contents {
      padding-top: 20vh;
      text-align: center;
    }

    h2 {
      text-align: center;
      color: #FFF;
      font-family: 'Pacifico', sans-serif;
      font-size: 55px;;
      font-weight: normal;
      transform: rotate(-10deg);
      margin: 0 0 30px;
    }

    .heart-divider {
      height: 100%;
      width: 100%;
      margin: 20px auto;
      text-align: center;
    }

    .white-line {
      width: 50px;
      height: 8px;
      display: inline-block;
      border-top: solid 1px #FFF;
    }

    .pink-heart {
      color: #f0394d;
      font-size: 19px;
      position: relative;
      z-index: 3;
    }

    .white-heart {
      color: #FFF;
      font-size: 18px;
      margin-left: -15px;
      position: relative;
      z-index: 2;
    }

    .names {
      font-size: 30px;
      color: white;
      margin-top: 30px;
      margin-bottom: 20px;
      font-weight: 500;
      font-family: 'Oswald', sans-serif;
      line-height: 1.1;
    }
  }


}
