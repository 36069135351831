.Uploader {
  .DropZone {
    outline: none;

    text-align: center;
    background-color: #eaeaea;
    padding: 60px 40px 40px;
    margin-bottom: 50px;
    cursor: pointer;


    img {
      height: 50px;
      width: 50px;
    }
  }

  .uploads {
    display: flex;
    font-family: 'Asap', sans-serif;
    padding: 0;
    flex-wrap: wrap;

    p {
      background-color: #e4e6e8;
      padding: 4px 8px 3px;
      border-radius: 4px;
      margin: 4px 4px 4px 0;
    }

    p.green {
      background-color: #d6e8c9;
    }
    p.red {
      background-color: #e8b8be;
    }

  }
}