
.LoginPage {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .left-box {
    background: url(photos-by-lanty-575232-unsplash-min-min.jpg) no-repeat center center;
    background-size: cover;
    width: 50vw;
    height: 100vh;
  }

  .right-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50vw;
    height: 100vh;
    overflow: scroll;
  }

  @media (max-aspect-ratio: 8/5) {
    .left-box {
      display: none;
    }

    .right-box {
      width: 100vw;
    }
  }

  @media (max-height: 600px) {
    .left-box {
      display: none;
    }

    .right-box {
      width: 100vw;
    }
  }

  .login-box {
    margin-bottom: 10vh;
    width: 230px;
  }

  h2 {
    font-family: 'Oswald', sans-serif;
    font-size: 40px;
    margin: 10vh 0 20px;
    font-weight: 500;
    line-height: 1.1;
  }

  .pink-heart {
    color: #f0394d;
  }

  .date {
    font-family: 'Oswald', sans-serif;
    text-align: center;
    color: #000000;
    letter-spacing: 3px;
    font-size: 17px;
    margin: 0 0 10vh;
  }

  .label {
    font-size: 0.7rem;
  }

  .formLiner {
    display: flex;
    margin: 0 0 3vh;
  }

  input {
    display: block;
    margin: 3px 3px 3px 0;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #e4e6e8;
    transition: 0.1s ease;
  }

  input[type=submit] {
    cursor: pointer;
    background-color: white;
  }

  input[type=submit]:disabled {
    cursor: default;
  }


  .sub-text {
    font-size: 0.7rem;
  }

  @media (max-width:500px)  {
    .login-box {
      width: 96%;
      padding: 0 2%;
    }
    .label {
      font-size: 0.8rem;
    }
    input  {
      margin-left: 0;
      margin-right: 0;
      font-size: 1rem;
    }
    .formLiner {
      flex-direction: column;
    }

  }

}
