.Gallery {
  background-color: #f6f6f6;
  padding: 60px 0;

  h2 {
    font-size: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #333333;
    font-family: 'Oswald', sans-serif;
    font-weight: 500;
    line-height: 1.1;
    text-align: center;
  }

  .heart-divider {
    height: 100%;
    width: 100%;
    margin: 40px auto 20px;
    text-align: center;
  }

  .grey-line {
    width: 50px;
    height: 8px;
    display: inline-block;
    border-top: solid 2px #e1dddd;
  }

  .pink-heart {
    color: #f0394d;
    font-size: 19px;
    position: relative;
    z-index: 3;
  }

  .grey-heart {
    color: #e1dddd;
    font-size: 19px;
    margin-left: -15px;
    position: relative;
    z-index: 2;
  }

  .text {
    font-family: 'Roboto Slab', serif;
    font-size: 14px;
    line-height: 21px;
    text-align: justify;
    margin: 0 auto 40px;
    width: 86vw;
  }


  .container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }


  @media (min-width: 768px) {
    .container, .text {
      width: 740px;
    }
  }

  @media (min-width: 992px) {
    .container, .text {
      width: 950px;
    }
  }



  @media (max-width: 768px) {
    .container {
      //flex-direction: column;
    }
    .cell {
      //width: 90%;
      //margin: 3%;
    }
    .white {
      padding: 2%;
    }
  }

  input {
    width: 318px;
    box-sizing: border-box;
    margin: 0 0 15px;
    display: block;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #e4e6e8;
  }


  input[type=submit], input[type=button] {
    cursor: pointer;
    background-color: white;
  }

  input[type=submit]:disabled, input[type=button]:disabled {
    cursor: default;
  }


  .remove {
    border: 1px solid #f0394d;
    color: #f0394d;
    font-weight: bold;
  }


  .CheckboxInput {
    width: 310px;;
    margin: 0 0 15px;
  }
  .PhoneInput {
    width: 318px;;
    margin: 0 0 15px;
  }

  @media (max-width:500px)  {

    .text {
      width: 96vw;
    }
    .container, .cell {
      width: 96vw;
      padding: 0;
    }
    input {
      font-size: 1rem;
      margin-left: 0;
      margin-right: 0;
      width: 96vw;
      box-sizing: border-box;
    }

    .CheckboxInput {
      padding: 6px 5px;
      font-size: 1rem;
      width: 96vw;
      box-sizing: border-box;
    }
    .PhoneInput {
      width: 96vw;
      box-sizing: border-box;
    }

  }
  .editText {
    font-size: 0.8rem;
    margin: 0 0 30px;
    span {
      font-weight: bold;
      color: #f0394d;
      cursor: pointer;
    }
  }

  .label {
    font-size: 0.7rem;
  }

  .formLiner {
    display: flex;
    margin: 0 0 3vh;
  }

  .freeze {
    input.TextInput {
      background-color: white;
      color: #6B6B6B;
    }
    .CheckboxInput {
      background-color: white;
      color: #6B6B6B;
    }
    input.PhoneInput {
      background-color: white;
      color: #6B6B6B;
    }
    .CheckboxInput.selected i {
      color: #6B6B6B;
    }
  }

  .FullScreen {
    z-index: 10;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    background-color: #000;
    align-items: center;
    justify-content: center;

    .main {
      max-width: 100vw;
      max-height: 95vh;
      margin-bottom: 5vh;
    }

    .close {
      position: fixed;
      top: 4px;
      right: 4px;
      z-index: 100;
      cursor: pointer;
      width: 40px;
      height: 40px;
    }

    .next {
      position: fixed;
      top: 0;
      right: 0;
      z-index: 90;
      cursor: pointer;
      width: 80vw;
      height: 95vh;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      img {
        height: 40px;
        width: 40px;
        margin-right: 2vw;

      }
    }

    .prev {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 90;
      cursor: pointer;
      width: 20vw;
      height: 95vh;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      img {
        height: 40px;
        width: 40px;
        margin-left: 2vw;

      }
    }
    .buttons {
      position: fixed;
      bottom: 0;
      right: 0;
      z-index: 90;
      img {
        height: 40px;
        width: 40px;
        cursor: pointer;
      }
    }

    .name {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 90;
      width: 40vw;
      height: 5vh;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #e0e0e0;
      margin: 0 1vh;
    }
  }



  .Photos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    width: 90vw;
    padding-left: 3px;

    .imgBox {
      margin: 3px 3px 0 0;
      width: 200px;
      height: 200px;
      position: relative;
      cursor: pointer;

      div {
        position: absolute;
        display: none;
        bottom: 0;
        left: 0;
        z-index: 3;
        width: 94%;
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgb(0, 0, 0));
        /* height: 13px; */
        color: #e0e0e0;
        padding: 10% 3% 3%;
        justify-content: space-between;
        p {
          margin: 0;
        }
        img {
          width: 18px;
          height: 18px;
        }

      }
    }
    .imgBox.fake {
      cursor: auto;
    }
    .imgBox:hover div {
      display: flex;
    }

    img {
      display: block;
      object-fit: cover;
      width: 200px;
      height: 200px;
    }
  }



  @media (max-width: 680px) {
    .Photos {
      margin: 0 0 0 0;
      width: 100vw;
      justify-content: space-between;
      padding: 0;
      .imgBox {
        //width: 32vw;
        //height: 32vw;
        //margin: 1vw 0 0 1vw;
        width: 33vw;
        height: 33vw;
        margin: 0 0 0.5vw;
        div {
          display: flex;
          font-size: 13px;
          background-image: none;
          p {
            display: none;
          }
        }
        img {
          width: 33vw;
          height: 33vw;
        }
      }
    }
  }

  .GalleryMenu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;

    div {
      font-size: 20px;
      margin: 0 30px 40px;
      cursor: pointer;
    }


    div.active {
      border-bottom: 1px black solid;
    }
  }

}